<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import ApiService from "@/services/jobsiteTrackingService";
import FilesModal from "@/components/modals/filesModal";
import PageHeader from "@/components/page-header";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import WorkOrderHelper from "@/utils/workOrderHelper";

/**
 * Dashboard Component
 */
export default {
  page: {
    title: "Dashboard",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    FilesModal,
    Loading,
    PageHeader
  },
  data() {
    return {
      items: null,
      isLoading: false,
      chartOptions: {
        chart: {
          height: 90,
          type: "radialBar",
          sparkline: {
            enabled: true,
          },
          offsetX: -20,
        },
        colors: ["#34c38f"],
        plotOptions: {
          radialBar: {
            startAngle: 0,
            endAngle: 360,
            track: {
              background: "#f8f9fa",
              strokeWidth: "97%",
              margin: 5, // margin is in pixels
            },
            hollow: {
              size: "24%",
            },

            dataLabels: {
              name: {
                show: false,
              },
              value: {
                offsetY: 5,
                fontSize: "12px",
              },
            },
          },
        },
        grid: {
          padding: {
            top: 0,
          },
        },
        stroke: {
          dashArray: 0,
        },
        labels: ["Storage"],
      },
      searchText: "",
      filter: "",
      sort: "deliveryDate",
      sortDirection: "asc",
      pagesize: 18,
      totalRows: 0,
      showCompleted: false,
      selectedView: "withpacking",
      selectedShipper: null,
      selectedInstaller: null,
    };
  },
  mounted() { },
  methods: {
    getData() {
      this.isLoading = true;
      var request = { page: 1, pageSize: this.pagesize, search: this.filter, sortBy: this.sort, sortDesc: this.sortDirection == 'desc', completed: this.showCompleted };
      var promise = this.selectedView === "withpacking" ? 
        ApiService.getPackings(request): 
        ApiService.getWorkOrders(request);
      return promise
        .then((response) => {
          this.items = response.data.items
          this.totalRows = response.data.totalCount;
        })
        .catch((error) => {
          window.console.error(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    gotoworkorder(workorderid, packingIdPublic) {
      this.$router.push({
        name: "products",
        params: { workorderid: workorderid },
        query: { packing: packingIdPublic },
      });
    },
    setSortDeliveryDate() {
      if (this.sort == "deliveryDate" && this.sortDirection == "asc") {
        if (this.sortDirection == "asc") {
          this.sortDirection = "desc";
        } else {
          this.sortDirection = "asc";
        }
      } else {
        this.sort = "deliveryDate";
        this.sortDirection = "asc";
      }
    },
    setSortInstallationDate() {
      if (this.sort == "installationDate" && this.sortDirection == "asc") {
        if (this.sortDirection == "asc") {
          this.sortDirection = "desc";
        } else {
          this.sortDirection = "asc";
        }
      } else {
        this.sort = "installationDate";
        this.sortDirection = "asc";
      }
    },
    fullAddress(workOrder) {
      return WorkOrderHelper.getFullAddress(workOrder);
    },
    reload(e) {
      if (e == "/") {
        this.getData();
      }
    },
    loadMore() {
        this.pagesize = this.pagesize + 9;
        this.getData();
    }
  },
  computed: {
    installers() {
      var listInstallers = [].concat.apply(
        [],
        [].concat
          .apply(
            [],
            this.packings?.map((x) => x.workOrderPackingInfoResources)
          )
          .map((x) => x.installers)
      );
      return [...new Set(listInstallers.map((s) => s.key))]
        .map((x) => {
          return {
            value: x,
            text: listInstallers.find((s) => s.key === x).value,
          };
        })
        .concat([
          { value: null, text: this.$t("general.nofilter") },
          { value: "0", text: this.$t("general.noinstaller") },
        ])
        .sort((a, b) =>
          a.value == null
            ? -1
            : b.value == null
              ? 1
              : a.value.localeCompare(b.value)
        );
    },
    shippers() {
      var listShippers = [].concat
        .apply(
          [],
          this.packings?.map((x) => x.workOrderPackingInfoResources)
        )
        .filter((x) => x.shipper != null)
        .map((x) => x.shipper);
      return [...new Set(listShippers.map((s) => s.key))]
        .map((x) => {
          return {
            value: x,
            text: listShippers.find((s) => s.key === x).value,
          };
        })
        .concat([
          { value: null, text: this.$t("general.nofilter") },
          { value: "0", text: this.$t("general.noshipper") },
        ])
        .sort((a, b) =>
          a.value == null
            ? -1
            : b.value == null
              ? 1
              : a.value.localeCompare(b.value)
        );
    },
    viewOptions() {
      return [
        {
          value: "withpacking",
          text: this.$t("general.withpacking"),
        },
        {
          value: "nopacking",
          text: this.$t("general.nopacking"),
        },
      ];
    },
  },
  watch: {
    selectedView() {
      this.items = [];
      this.getData();
    },
    searchText() {
      if (typeof window.LIT !== 'undefined') {
        clearTimeout(window.LIT);
      }
      window.LIT = setTimeout(() => {
        this.filter = this.searchText;
        this.getData();
      }, 500);
    },
    sort() {
      this.getData();
    },
    sortDirection() {
      this.getData();
    },
    showCompleted() {
      this.getData();
    }
  },
  created() {
    this.getData();
  },
};
</script>

<template>
  <Layout @reload="reload">
    <PageHeader :title="$t('general.mypackinglist')" />
    <div class="m-1 mb-3 card">
      <div class="card-body p-2 pl-4 pr-4">
        <div class="row justify-content-start">
          <div class="col-lg-3 col-md-6 p-2">
            <b-form-input id="searchText" v-model="searchText" :placeholder="$t('general.search')" class="m-1" />
          </div>
          <b-button @click="setSortDeliveryDate" class="m-2 col-auto">
            <i class="mdi mdi-chevron-down mdi-18px align-middle"
              v-if="sortDirection == 'desc' && sort == 'deliveryDate'"></i>
            <i class="mdi mdi-chevron-up mdi-18px align-middle"
              v-if="sortDirection == 'asc' && sort == 'deliveryDate'"></i>{{ $t("general.deliverydate") }}
          </b-button>
          <b-button @click="setSortInstallationDate" class="m-2 col-auto">
            <i class="mdi mdi-chevron-down mdi-18px align-middle"
              v-if="sortDirection == 'desc' && sort == 'installationDate'"></i>
            <i class="mdi mdi-chevron-up mdi-18px align-middle"
              v-if="sortDirection == 'asc' && sort == 'installationDate'"></i>{{ $t("general.installationdate") }}
          </b-button>
          <div class="d-flex align-items-center col-auto">
            <b-form-select v-model="selectedShipper" v-if="shippers.length > 2" :options="shippers"
              style="max-width: 200px" class=""></b-form-select>
          </div>
          <div class="d-flex align-items-center col-auto">
            <b-form-select v-if="installers.length > 2" v-model="selectedInstaller" :options="installers"
              style="max-width: 200px" class="calendar-select-view"></b-form-select>
          </div>
          <div class="d-flex align-items-center col-auto ml-xl-auto">
            <h5 class="p-1 m-2 align-middle">{{ $t("general.completed") }}</h5>
            <b-form-checkbox switch size="lg" v-model="showCompleted" class=""></b-form-checkbox>
          </div>
          <div class="d-flex align-items-center col-auto">
            <b-form-select v-model="selectedView" :options="viewOptions" style="max-width: 200px"
              class="calendar-select-view"></b-form-select>
          </div>
        </div>
      </div>
    </div>
    <loading :active.sync="isLoading" :can-cancel="false" color="#f1b44c" :width="70" :height="70" loader="dots"
      :is-full-page="false">
    </loading>
    <div class="row align-items-start d-flex" style="min-height: 0; overflow-y: scroll; position: relative">
      <template v-for="(workOrder, index) in items">
        <div class="col-xl-4 col-lg-6 col-xs-12" :key="'nopacking' + index" v-if="selectedView == 'nopacking'">
          <div class="card border">
            <div class="card-body">
              <div class="row">
                <div class="col-6">
                  <div style="font-size: 1.5em">
                    {{ workOrder.name }}
                  </div>
                  <div class="row" style="font-size: 1.3em">
                    <div class="col-4 text-nowrap">
                      <i class="mdi mdi-24px mdi-cube-outline align-middle"></i><span class="p-1">{{ workOrder.nbProducts
                      }}</span>
                    </div>
                    <div class="col-4 text-nowrap">
                      <i class="mdi mdi-24px mdi-puzzle-outline align-middle"></i><span class="p-1">{{
                        workOrder.nbDetached }}</span>
                    </div>
                    <div class="col-4 text-nowrap">
                      <i class="
                          mdi mdi-24px mdi-alert-outline
                          text-danger
                          align-middle
                        "></i><span class="p-1">{{ workOrder.nbWarning }}</span>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="d-flex flex-row justify-content-end">
                    <div role="button" class="p-2" @click="$refs.filesModal.openEventDialog(workOrder)">
                      <i class="mdi mdi-36px mdi-file-document-multiple-outline"></i>
                    </div>
                    <div role="button" class="p-2" v-if="fullAddress(workOrder)">
                      <a target="_blank" :href="'http://maps.google.com/?q=' + fullAddress(workOrder)
                        "><i class="
                            mdi mdi-36px mdi-map-marker-outline
                            text-success
                          "></i></a>
                    </div>
                    <div class="p-2" v-if="!fullAddress(workOrder)">
                      <i class="mdi mdi-36px mdi-map-marker-outline"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-between m-1">
                <div class="d-flex justify-content-start">
                  <div v-for="(workOrderStep, index) in workOrder.workOrderSteps" :key="index" class="p-1">
                    <span class="badge badge-secondary p-2" style="font-size: 0.8em"
                      v-if="workOrderStep.completedJobsite">
                      <i class="mdi mdi-check align-middle" v-if="workOrderStep.completedJobsite"></i>
                      {{ workOrderStep.stepName }}
                    </span>
                    <b-button disabled role="text" v-if="!workOrderStep.completedJobsite" variant="outline-primary"
                      class="p-1" style="font-size: 0.8em">{{ workOrderStep.stepName }}</b-button>
                  </div>
                </div>
                <div role="button" @click="gotoworkorder(workOrder.idPublic)">
                  <span style="text-decoration: underline !important">{{
                    $t("general.seeprofile")
                  }}</span>
                  &#8594;
                </div>
              </div>
              <div class="d-flex justify-content-between">
                <div>
                  {{ $t("general.abrdeliverydate") }} :
                  {{
                    $dayjs(workOrder.deliveryDate + "Z").format("YYYY-MM-DD")
                  }}
                </div>
                <div>
                  {{ $t("general.abrinstallationdate") }} :
                  {{
                    $dayjs(workOrder.installationDate + "Z").format(
                      "YYYY-MM-DD"
                    )
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-for="(packing, index) in items">
        <div class="col-xl-4 col-lg-6 col-xs-12" :key="'withpacking' + index" v-if="selectedView == 'withpacking'">
          <div class="card">
            <div class="card-body">
              <div class="card-title mb-4">{{ packing.name }}</div>
              <div style="
                  height: 400px;
                  overflow: auto;
                  scrollbar-color: #dee0e3 transparent;
                  scrollbar-width: thin;
                ">
                <div v-for="(
                    workOrder, index
                  ) in packing.workOrderPackingInfoResources" :key="index">
                  <div class="card border">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-6">
                          <div style="font-size: 1.5em">
                            {{ workOrder.name }}
                          </div>
                          <div class="row" style="font-size: 1.3em">
                            <div class="col-4 text-nowrap">
                              <i class="
                                  mdi mdi-24px mdi-cube-outline
                                  align-middle
                                "></i><span class="p-1">{{
                                  workOrder.nbProducts
                                }}</span>
                            </div>
                            <div class="col-4 text-nowrap">
                              <i class="
                                  mdi mdi-24px mdi-puzzle-outline
                                  align-middle
                                "></i><span class="p-1">{{
                                  workOrder.nbDetached
                                }}</span>
                            </div>
                            <div class="col-4 text-nowrap">
                              <i class="
                                  mdi mdi-24px mdi-alert-outline
                                  text-danger
                                  align-middle
                                "></i><span class="p-1">{{
                                  workOrder.nbWarning
                                }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="d-flex flex-row justify-content-end">
                            <div role="button" class="p-2" @click="
                            $refs.filesModal.openEventDialog(workOrder)
                              ">
                              <i class="
                                  mdi
                                  mdi-36px
                                  mdi-file-document-multiple-outline
                                "></i>
                            </div>
                            <div role="button" class="p-2" v-if="fullAddress(workOrder)">
                              <a target="_blank" :href="'http://maps.google.com/?q=' +
                                fullAddress(workOrder)
                                "><i class="
                                    mdi mdi-36px mdi-map-marker-outline
                                    text-success
                                  "></i></a>
                            </div>
                            <div class="p-2" v-if="!fullAddress(workOrder)">
                              <i class="mdi mdi-36px mdi-map-marker-outline"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="d-flex justify-content-between m-1">
                        <div class="d-flex justify-content-start">
                          <div v-for="(
                              workOrderStep, index
                            ) in workOrder.workOrderSteps" :key="index" class="p-1">
                            <span class="badge badge-secondary p-2" style="font-size: 0.8em"
                              v-if="workOrderStep.completedJobsite">
                              <i class="mdi mdi-check align-middle" v-if="workOrderStep.completedJobsite"></i>
                              {{ workOrderStep.stepName }}
                            </span>
                            <b-button disabled role="text" v-if="!workOrderStep.completedJobsite"
                              variant="outline-primary" class="p-1" style="font-size: 0.8em">{{ workOrderStep.stepName
                              }}</b-button>
                          </div>
                        </div>
                        <div role="button" @click="
                          gotoworkorder(workOrder.idPublic, packing.idPublic)
                          ">
                          <span style="text-decoration: underline !important">{{
                            $t("general.seeprofile")
                          }}</span>
                          &#8594;
                        </div>
                      </div>
                      <div class="d-flex justify-content-between">
                        <div>
                          {{ $t("general.abrdeliverydate") }} :
                          {{
                            $dayjs(workOrder.deliveryDate + "Z").format(
                              "YYYY-MM-DD"
                            )
                          }}
                        </div>
                        <div>
                          {{ $t("general.abrinstallationdate") }} :
                          {{
                            $dayjs(workOrder.installationDate + "Z").format(
                              "YYYY-MM-DD"
                            )
                          }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
    <div class="w-100">
      <div class="p-2 border-top" v-show="items && totalRows != items.length">
        <a class="btn btn-sm btn-light btn-block text-center" @click="loadMore">
          <i class="mdi mdi-arrow-down-circle mr-1"></i>
          {{ $t("navbar.dropdown.notification.button") }}
        </a>
      </div>
    </div>
    <FilesModal ref="filesModal" />
  </Layout>
</template>